import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./image/logo512.png";
import Score from "./component-admin/Score";
import Monitor from "./component-admin/monitor";
import HomeADMIN from "./component-admin/homeAdmin";
import Ranking from "./component-admin/ranking";
import Leaderboard from "./component-admin/leaderboard";
import PaymentService from "./component-admin/publish_Dashboard";
import CheckTicket from "./component-admin/checkticket";
import TopVoteAndSpen from "./component-admin/TopVoteandSpender";
import { useEffect } from "react";

const AdminHome = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    console.log("userID", id);
  });

  useEffect(() => {
    console.log("userID", id);

    // กำหนดค่า activeTab ตาม userId
    if (id === "A001") {
      setActiveTab("Payment");
    } else if (id === "A002") {
      setActiveTab("CheckTicket");
    } else if (id === "A003") {
      setActiveTab("monitor");
    }
  }, [id]); // เพิ่ม [id] เพื่อให้การเปลี่ยนแปลง id ถูกตรวจจับ

  const accessibleTabs = {
    A001: ["Payment"],
    A002: ["CheckTicket"],
    A003: [
      "home",
      "monitor",
      "StationRanking",
      "CheckTicket",
      "Leaderboard",
      "Payment",
      "TopVoteAndSpen"
    ],
  };

  const allowedTabs = accessibleTabs[id] || [];

  // ฟังก์ชันเรนเดอร์เนื้อหาตามแท็บที่เลือก
  const renderContent = () => {
    switch (activeTab) {
      case "monitor":
        return <Monitor />;
      case "home":
        return <HomeADMIN />;
      case "Score":
        return <Score />;
      case "StationRanking":
        return <Ranking />;
      case "Leaderboard":
        return <Leaderboard />;
      case "CheckTicket":
        return <CheckTicket />;
      case "Payment":
        return <PaymentService />;
        case "TopVoteAndSpen":
        return <TopVoteAndSpen />;
      default:
        return <h2>Welcome to the Admin Panel</h2>;
    }
  };

  return (
    <div className="container mt-3">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "30px", marginRight: "10px" }}
            />
            Admin
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              {allowedTabs.includes("home") && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "home" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("home")}
                    role="button"
                  >
                    Home
                  </button>
                </li>
              )}
              {allowedTabs.includes("monitor") && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "monitor" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("monitor")}
                    role="button"
                  >
                    Monitor
                  </button>
                </li>
              )}
              {allowedTabs.includes("StationRanking") && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "StationRanking" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("StationRanking")}
                    role="button"
                  >
                    StationRanking
                  </button>
                </li>
              )}
              {allowedTabs.includes("Leaderboard") && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "Leaderboard" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Leaderboard")}
                    role="button"
                  >
                    Leaderboard
                  </button>
                </li>
              )}
              {allowedTabs.includes("CheckTicket") && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "CheckTicket" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("CheckTicket")}
                    role="button"
                  >
                    CheckTicket
                  </button>
                </li>
              )}
              {allowedTabs.includes("Payment") && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "Payment" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Payment")}
                    role="button"
                  >
                    Publish Dashboard
                  </button>
                </li>
              )}
               {allowedTabs.includes("TopVoteAndSpen") && (
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "TopVoteAndSpen" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("TopVoteAndSpen")}
                    role="button"
                  >
                    TopVote And Spen
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {/* Body Content */}
      <div className="mt-4">{renderContent()}</div>
    </div>
  );
};

export default AdminHome;
