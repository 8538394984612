import React, { useState, useEffect } from "react";
import api from "./../api";

const TopVoteAndSpen = () => {
  const [topData, setTopData] = useState(null);
  const [spendData, setSpendData] = useState(null);

  const handleVoteSpendata = async () => {
    try {
      const response = await api.post("/getTopfilterdataPayment");
      const resData = response.data;

      // แยกข้อมูลเป็นสองส่วน
      setTopData({
        highestScore: resData.highestScore,
        highestContID: resData.highestContID,
        ID: resData.contData.ID,
        Measurements: resData.contData.Measurements,
        Name: resData.contData.Name,
        Nickname: resData.contData.Nickname,
      });

      setSpendData(resData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleVoteSpendata();
    const interval = setInterval(handleVoteSpendata, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary mb-4 fw-bold">Top Vote and Spender</h2>

      {topData && spendData && (
        <div className="row">
          <div className="col-md-6">
            <div className="card p-4 mb-3 shadow rounded-3 border-0">
              <h4 className="text-center text-success fw-bold mb-3">Top Vote</h4>
              <p><strong>ID:</strong> {topData.ID}</p>
              <p><strong>Name:</strong> {topData.Name}</p>
              <p><strong>Nickname:</strong> {topData.Nickname}</p>
              <p><strong>Result Score:</strong> <span className="text-primary fw-bold">{topData.highestScore}</span></p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card p-4 shadow rounded-3 border-0">
              <h4 className="text-center text-warning fw-bold mb-3">Spender Data</h4>
              <p><strong>Full Name:</strong> {spendData.fullName}</p>
              <p><strong>Email:</strong> {spendData.email}</p>
              <p><strong>Phone Number:</strong> {spendData.phonenumber}</p>
              <p><strong>Contestant ID:</strong> {spendData.contID}</p>
              <p><strong>Score:</strong> <span className="text-danger fw-bold">{spendData.score}</span></p>
              <p><strong>Price:</strong> <span className="text-success fw-bold">{spendData.price}</span></p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopVoteAndSpen;
