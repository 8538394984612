import React, { useState, useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./css/Login.css";
import logo from "./image/logo512.png";
import { auth, signInWithEmailAndPassword } from "../auth/firebase";
import { useNavigate } from "react-router-dom";
import api from "./api";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [DirectorID, setDirectorID] = useState("");
  const [roleData, setroleData] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    await getEmail();
    console.log("email", email);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (roleData === "Admin") {
        navigate(`/admin/${userId}`);
      } else if (roleData === "director") {
        navigate(`/home-comming/${DirectorID}`, { state: { email, username, DirectorID } });
      }
    } catch (error) {
      console.error("Login failed:", error.message);
      message.error("Login failed: Incorrect username or password");
    }
  };

  const getEmail = async () => {
    console.log("Login info:", userId);
    try {
      const response = await api.post(`/loginDirectors/${userId}`);
      const { email, Name: username, ID: DirectorID, role } = response.data.data;
      setEmail(email);
      setUsername(username);
      setDirectorID(DirectorID);
      setroleData(role);
    } catch (error) {
      console.error("Login error:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (userId) getEmail();
  }, [userId]);

  return (
    <div className="login-container">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <Form
        name="login-form"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={handleLogin}
        style={{ maxWidth: "400px", margin: "auto" }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please enter your username!" }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Username"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button gradient-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
