import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import logo from "./image/logo512.png";
import api from "./api";
import Swal from "sweetalert2";

const Vote = () => {
  const location = useLocation();
  const [ContsData, SetData] = useState();
  const [stationData, setStationData] = useState({});
  const [DocumentId, setDocumentId] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [dataMonitor, setAlldataMonitor] = useState({});
  const [DataStation, setDataStation] = useState("");
  const [isStopped, setIsStopped] = useState(false);
  const [isTimer, setIsTimer] = useState(false);
  const [countdown, setCountdown] = useState(1);
  const [timecountdown, setTimecountdown] = useState(1000);
  const [averages, setAverages] = useState({});

  const navigate = useNavigate();
  const username = location.state?.username;
  const email = location.state.email;
  const userid = location.state?.id;
  const DirectorID = location.state?.DirectorID;
  const contestants = location.state?.contestants || [];
  const [editStation, setEditStation] = useState(null); // เพิ่ม state เพื่อเก็บสถานีที่กำลังถูกแก้ไข
  const [scores, setScores] = useState({});
  // const [activeStation, setActiveStation] = useState(null);

  const handleSelectContestant = (id) => {
    if (id) {
      console.log("id: ---------------------------------- ", id);
      setScores({});
      setMonitor(id);
      const contestantStationData = stationData[id] || {};
      const documentNames = contestantStationData.documentNames || [];
      setTimeout(() => {
        navigate(`/vote/${DirectorID}`, {
          state: { username, id, contestants, DirectorID, documentNames },
        });
        window.location.reload();
      }, 1000);
    }
  };

  const handleAdmincontrol = (id) => {
    if (id) {
      setIsStopped(false);
      // setScores({});
      if (!DocumentId.includes(DataStation)) {
        handleautoSubmit(DataStation);
      }
      const contestantStationData = stationData[id] || {};
      const documentNames = contestantStationData.documentNames || [];
      window.scrollTo(0, 0);
      setTimeout(() => {
        navigate(`/vote/${DirectorID}`, {
          state: { username, id, contestants, DirectorID, documentNames },
        });
        window.location.reload();
      }, 1500);
    }
  };

  const handleAdmincontrolintruction = () => {
    navigate(`/home-comming/${DirectorID}`, { state: { email, username, DirectorID } }); // ส่ง userName ไปยัง ListConts
  };

  const getAllmonitor = async () => {
    try {
      const response = await api.post("/getAllmonitor");
      setAlldataMonitor(response.data.data);
      const data = response.data.data;
      const station = data["Station"];
      setDataStation(station);
      // console.log("station", station);
    } catch (error) {
      console.error("Error fetching monitor data:", error);
    }
  };

  const setDelay = async () => {
    console.log("data monitor: ", dataMonitor["delay"]);
    const dataDelay = dataMonitor["delay"];
    const timedelay = dataDelay * 1000;
    setCountdown(dataDelay);
    setTimecountdown(timedelay);
  };

  const Admin_control = async () => {
    // ตรวจสอบข้อมูลทั้งหมดใน dataMonitor
    const monitorData = dataMonitor[DirectorID];
    console.log(monitorData);

    // ตรวจสอบว่ามี monitorData หรือไม่
    if (monitorData === undefined || monitorData === null) {
      console.log(`No monitor data found for DirectorID: ${DirectorID}`);
      return;
    }

    if (monitorData === userid) {
      console.log(`DirectorID: ${DirectorID} is currently set to ${userid}`);
      setDelay();
    } else {
      console.log(
        `DirectorID: ${DirectorID} is currently monitoring: ${monitorData}`
      );
      setIsStopped(true); // ตั้งค่าการหยุด
      setIsTimer(true); // ตั้งค่าการเริ่มเวลา

      // ใช้ setTimeout ในการรอ 3 วินาที (5000ms)
      if (monitorData === "Dashboard") {
        setTimeout(() => {
          handleImagesClick();
        }, timecountdown);
      } else if (monitorData === "Instruction") {
        setTimeout(() => {
          handleAdmincontrolintruction();
        }, timecountdown);
      } else {
        setTimeout(() => {
          handleAdmincontrol(monitorData);
        }, timecountdown);
      }
    }
  };

  const handleEditScore = (station) => {
    console.log("handleEditScore");
    console.log("station: ", station);
    console.log("userid: ", userid);
    setEditStation(station);
    fetchScoreData(station, DirectorID); // ดึงคะแนนของสถานีนั้นโดยใช้ `station` และ `userid`
  };

  const fetchScoreData = async (station, DirectorID) => {
    try {
      const response = await api.post(`/getEvaluationConts/${userid}`);
      const evaluations = response.data.data;

      // console.log("Evaluations Data:", evaluations);
      const evaluation = evaluations.find((evaluation) => evaluation[station]);
      // console.log("evaluation Data:", evaluation);

      if (evaluation) {
        const stationData = evaluation[station];
        // console.log("contestantId: ",DirectorID)
        // console.log("stationData: ",stationData)
        if (stationData && stationData[DirectorID]) {
          const contestantData = stationData[DirectorID];
          const stationScore = contestantData.score || {}; // หรือใช้ข้อมูลที่ต้องการจาก contestantData

          // console.log(`Scores found for ${station} - ${DirectorID}:`, stationScore);

          setScores(stationScore);
        } else {
          console.log(`Contestant ID ${DirectorID} not found in ${station}`);
        }
      } else {
        console.log(`Station ${station} not found in evaluations`);
      }
    } catch (error) {
      console.error("Error fetching score data:", error);
    }
  };

  useEffect(() => {
    let timerInterval;
    if (isTimer && countdown > 0) {
      timerInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000); // นับถอยหลังทีละ 1 วินาที
    }

    // หยุดนับถอยหลังเมื่อถึง 0
    if (countdown === 0) {
      setIsTimer(false);
      clearInterval(timerInterval);
    }

    // เคลียร์ interval เมื่อ component ถูก unmount
    return () => clearInterval(timerInterval);
  }, [isTimer, countdown]);

  useEffect(() => {
    if (!isStopped) {
      const intervalId = setInterval(() => {
        getAllmonitor();
        console.log("userid: ", userid);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isStopped]);

  useEffect(() => {
    if (!isStopped && Object.keys(dataMonitor).length !== 0 && DirectorID) {
      Admin_control();
    }
  }, [dataMonitor, DirectorID, isStopped]);

  const getStation = async (Contsid) => {
    try {
      const response = await api.post(
        `/getDirDocument/${Contsid}/${DirectorID}`
      );
      setStationData((prevData) => ({ ...prevData, [Contsid]: response.data }));
    } catch (error) {
      console.error("Error fetching station data:", error);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredContestants = contestants.filter((contestant) =>
    contestant.ID ? contestant.ID.toString().includes(searchTerm) : false
  );

  const point = [1, 2, 3, 4, 5];
  const maxPoint = Math.max(...point);

  const dataStation = [
    {
      title: "หมวด 12 อ คิดสร้างสรรค์และ การตอบคำถามและทัศนคติ (ส่วนบุคคล)",
      station: "Station1",
      question: {
        Question1:
          "ความคิดสร้างสรรค์ – นำเสนอแนวคิดใหม่ แปลกใหม่ แตกต่าง และน่าประทับใจ",
        Question2: "ความเข้าใจ – แสดงความเข้าใจในหัวข้อ ลึกซึ้งและถูกต้อง",
        Question3: "การดึงดูดความสนใจ – นำเสนอได้น่าสนใจ ดึงดูดผู้ฟัง",
        Question4:
          "ความชัดเจนในการตอบคำถาม – ตอบตรงประเด็น ชัดเจน เข้าใจง่าย ครบถ้วน",
        Question5:
          "บุคลิกภาพและความมั่นใจในการตอบคำถาม – บุคลิกดี มั่นใจ น้ำเสียงและท่าทางชัดเจน น่าเชื่อถือ",
        Question6:
          "ความสมเหตุสมผลในการตอบคำถาม – ตอบอย่างมีเหตุผล เชื่อมโยงข้อมูลเหมาะสมในการตอบคำถาม",
        Question7:
          "ทักษะการใช้ภาษาในการตอบคำถาม – ใช้ภาษาชัดเจน สละสลวย ถ่ายทอดธรรมชาติ",
      },
      mainMaxpoint: 35,
    },
    {
      title: "หมวดเดินแบบชุดราตรี",
      station: "Station2",
      question: {
        Question1: "ร่างกายสมส่วน – ความสูงสัมพันธ์กับร่างกาย",
        Question2: "บุคลิกภาพและความมั่นใจ – บุคลิกดี มั่นใจ ดึงดูดสายตาผู้ชม",
        Question3: "ความสวยงามและความสมดุลของใบหน้า",
        Question4: "ความสวยงามของผิวพรรณ",
        Question5: "การเลือกชุดราตรีได้เหมาะสมกับบุคคล",
      },
      mainMaxpoint: 35,
    },
  ];

  useEffect(() => {
    const calculateAverages = () => {
      const newAverages = {};
      dataStation.forEach((data) => {
        const MaxScore = maxPoint * Object.keys(data.question).length;
        newAverages[data.station] = data.mainMaxpoint / MaxScore;
      });
      setAverages(newAverages);
    };

    if (dataStation.length > 0) {
      calculateAverages();
    }
  }, [dataStation, maxPoint]); // Ensure proper dependencies

  const setMonitor = async (Contsid) => {
    try {
      console.log(`set monitor form ${userid} tp ${Contsid} `);
      const response = await api.post(`/setmonitor`, {
        userID: Contsid,
        DirID: DirectorID,
      });
    } catch (error) {
      console.error("Error fetching station data:", error);
    }
  };

  useEffect(() => {
    isStationComplete();
  });

  const isStationComplete = () => {
    const contestantStationData = stationData[userid] || {};
    const documentNames = contestantStationData.documentNames || [];
    // console.log("contestantStationData: ", contestantStationData);
    // console.log("documentNames: ", documentNames);
    setDocumentId(documentNames);
  };

  useEffect(() => {
    getStation(userid);
  }, [userid, DirectorID]);

  useEffect(() => {
    const contestantData = contestants.find(
      (contestant) => contestant.ID === userid
    );
    SetData(contestantData);
  }, [contestants, userid]);

  const handleScoreChange = (station, questionIndex, scores) => {
    setScores((prevScores) => {
      const currentScoreKey = `${station}-Q${questionIndex}`;
      const currentScore = prevScores[currentScoreKey];

      // If the score is already set, remove it (toggle off)
      if (currentScore === scores) {
        const updatedScores = { ...prevScores };
        delete updatedScores[currentScoreKey]; // Unset the score
        // console.log(`Unset score for ${currentScoreKey}`);

        // คำนวณคะแนนรวมใหม่หลังจากลบค่า
        const totalScore = Object.entries(updatedScores)
          .filter(([key]) => key !== "result") // กรองเฉพาะรายการที่ไม่ใช่ result
          .reduce(
            (sum, [_, value]) => sum + (typeof value === "number" ? value : 0),
            0
          );

        updatedScores.result = totalScore; // เก็บคะแนนรวม อาจจะต้งเพิ่ม * 1.4;
        if (Object.keys(updatedScores).length === 1) {
          // มีแค่ result แปลว่าไม่มีคะแนน
          // console.log("All scores have been cleared.");
          // setActiveStation(null); // Enable all stations
        } else {
          console.log("Remaining scores:", updatedScores);
        }

        return updatedScores;
      }

      // Set the score for the selected question
      const newScores = {
        ...prevScores,
        [currentScoreKey]: scores,
      };

      // คำนวณคะแนนรวมใหม่เฉพาะรายการที่เป็นคำถามเท่านั้น
      const totalScore = Object.entries(newScores)
        .filter(([key]) => key !== "result") // กรองเฉพาะรายการที่ไม่ใช่ result
        .reduce(
          (sum, [_, value]) => sum + (typeof value === "number" ? value : 0),
          0
        );

      newScores.result = totalScore;

      return newScores;
    });
  };

  const fillMissingScores = (station) => {
    const questionIndexes = {
      Station1: [1, 2, 3, 4, 5, 6, 7],
      Station2: [1, 2, 3, 4, 5], // Station2: มีคำถาม 4 ข้อ
    };

    // เติมคะแนน 0 สำหรับคำถามที่ยังไม่มีคะแนน
    questionIndexes[station].forEach((questionIndex) => {
      const scoreKey = `${station}-Q${questionIndex}`;
      if (scores[scoreKey] === undefined) {
        scores[scoreKey] = 0; // หากไม่มีการให้คะแนน จะกำหนดเป็น 0
      }
    });
  };

  const handleautoSubmit = async (station) => {
    // // เติมคะแนนที่ขาดด้วย 0 ก่อนทำการ submit
    fillMissingScores(station);

    try {
      const requestBody = {
        userID: userid,
        DirID: DirectorID,
        station: station,
        score: scores,
      };

      const response = await api.post(`/setArrayScoreConts`, requestBody);

      console.log("Response:", response.data);

      // แสดง SweetAlert แจ้งการส่งสำเร็จ
      Swal.fire({
        title: "ส่งการประเมินสำเร็จ",
        text: "ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว",
        icon: "success",
        confirmButtonText: "ตกลง",
      }).then(() => {
        // รีโหลดหน้าหลังจากที่ผู้ใช้กด "ตกลง"
        window.location.reload();
      });
    } catch (error) {
      console.error("Error submitting scores:", error);

      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถส่งการประเมินได้ กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        confirmButtonText: "ตกลง",
        timer: 3000, // ตั้งเวลาให้แสดงการแจ้งเตือน 3 วินาที
        timerProgressBar: true, // แสดงแถบความคืบหน้า
      }).then(() => {
        // รีโหลดหน้าหลังจากที่ผู้ใช้กด "ตกลง"
        window.location.reload();
      });
    }
  };

  const handleSubmit = async (station) => {
    if (!isFormComplete(station)) {
      Swal.fire({
        title: "ข้อมูลไม่ครบถ้วน", // กำหนด title
        text: "ยังกรอกข้อมูลไม่ครบในสถานีนี้ กรุณาให้คะแนนครบทุกข้อก่อนส่งแบบประเมิน", // ข้อความ
        icon: "warning", // ไอคอนที่จะแสดง
        confirmButtonText: "ตกลง", // ข้อความบนปุ่ม
      });
      return;
    }

    try {
      const requestBody = {
        userID: userid,
        DirID: DirectorID,
        station: station,
        score: scores,
      };

      const response = await api.post(`/setArrayScoreConts`, requestBody);

      console.log("Response:", response.data);

      // แสดง SweetAlert แจ้งการส่งสำเร็จ
      Swal.fire({
        title: "ส่งการประเมินสำเร็จ",
        text: "ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว",
        icon: "success",
        confirmButtonText: "ตกลง",
      }).then(() => {
        // รีโหลดหน้าหลังจากที่ผู้ใช้กด "ตกลง"
        window.location.reload();
      });
    } catch (error) {
      console.error("Error submitting scores:", error);

      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถส่งการประเมินได้ กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        confirmButtonText: "ตกลง",
        timer: 3000, // ตั้งเวลาให้แสดงการแจ้งเตือน 3 วินาที
        timerProgressBar: true, // แสดงแถบความคืบหน้า
      }).then(() => {
        // รีโหลดหน้าหลังจากที่ผู้ใช้กด "ตกลง"
        window.location.reload();
      });
    }
  };

  const isFormComplete = (station) => {
    // กำหนดจำนวนคำถามตามสถานี
    const questionIndexes = {
      Station1: [1, 2, 3, 4, 5, 6, 7],
      Station2: [1, 2, 3, 4, 5],
    };

    // ตรวจสอบว่าคำถามทั้งหมดถูกตอบหรือไม่
    return questionIndexes[station].every(
      (questionIndex) => scores[`${station}-Q${questionIndex}`] !== undefined
    );
  };

  const handleImagesClick = () => {
    navigate(`/list-conts/${DirectorID}`, { state: { email, username, DirectorID } }); // ส่ง userName ไปยัง ListConts
  };

  // const isStationDisabled = (station) => {
  //   // ถ้ามี activeStation ให้ปิดการใช้งานสถานีที่ไม่ใช่ activeStation
  //   return activeStation !== null && activeStation !== station;
  // };

  return (
    <div className="container mt-4">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <span className="btn btn-primary" onClick={handleImagesClick}>
            <i className="bi bi-person-circle"></i> Dashboard
          </span>
          <span>
            <img
              src={logo}
              alt="Logo"
              className="img-fluid"
              onClick={handleImagesClick}
              style={{ maxWidth: "50px", maxHeight: "50px" }}
            />
          </span>
          <div className="d-flex align-items-center">
            {/* แสดงเวลานับถอยหลัง */}
            {isTimer && (
              <div className="text-white me-3">
                <i className="fa-solid fa-hourglass-half me-2"></i> นางงามถัดไป:{" "}
                {countdown} วินาที
              </div>
            )}
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                หมายเลขนางงาม: {userid || "000"}
              </button>
              <ul
                className="dropdown-menu p-2"
                aria-labelledby="dropdownMenuButton"
                style={{ minWidth: "200px" }}
              >
                {/* Search Bar */}
                <li>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ค้นหานางงาม..."
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {/* Dropdown items */}
                {filteredContestants.map((contestant) => (
                  <li key={contestant.ID}>
                    <button
                      className="dropdown-item"
                      onClick={() => handleSelectContestant(contestant.ID)}
                    >
                      {contestant.ID}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Layout แสดงรูปและชื่อนางงาม */}

      <div className="d-flex flex-column align-items-center my-4">
        {ContsData && (
          <>
            <div
              className="d-flex justify-content-center align-items-center mb-3"
              style={{
                width: "300px",
                height: "400px",
                borderRadius: "10%",
                overflow: "hidden",
              }}
            >
              <img
                src={require(`./image/uploads_misswellness/${ContsData.URL}`)}
                alt={ContsData.Name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // ครอบรูปให้พอดี
                }}
              />
            </div>

            {/* Text Content */}
            <div
              className="text-start"
              style={{
                width: "100%",
                maxWidth: "350px", // เพิ่มขนาดของข้อความให้กว้างขึ้น
                fontFamily: "Kanit, sans-serif",
              }}
            >
              <p className="mb-2">ชื่อ: {ContsData.Name}</p>
              <p className="mb-2">ชื่อเล่น: {ContsData.Nickname}</p>
              <p className="mb-2">อายุ: {ContsData.Age}</p>
              <p className="mb-2">สัดส่วน: {ContsData.Measurements}</p>
              <p className="mb-2" style={{ fontSize: "14px", color: "#666" }}>
                ข้อมูลอื่นๆ: {ContsData.Etc}
              </p>
            </div>
          </>
        )}
      </div>

      {/* Form ประเมิน */}
      <div className="container mt-5">
        <h3 className="text-center mb-4">ฟอร์มประเมินผล</h3>

        {dataStation.map((stationData) => {
          const isCompleted = Array.isArray(DocumentId)
            ? DocumentId.includes(stationData.station)
            : DocumentId === stationData.station;

          // เช็คว่า Station นี้เป็น Station ที่กำลังทำงานอยู่หรือไม่
          const isCurrentStation = stationData.station === DataStation;
          return (
            <div
              key={stationData.station}
              className="card mb-4 shadow-sm border-0"
            >
              <div className="card-header d-flex justify-content-between align-items-center bg-dark text-white">
                <h5 className="mb-0">{stationData.title}</h5>
                {isTimer && (
                  <div className="text-white me-3">
                    <i className="fa-solid fa-hourglass-half me-2"></i>{" "}
                    นางงามถัดไป: {countdown} วินาที
                  </div>
                )}
                {isCompleted && (
                  <span className="badge bg-success">
                    <i className="fa-solid fa-check-circle me-2"></i>{" "}
                    ประเมินเสร็จสิ้น
                  </span>
                )}
              </div>

              <div className="card-body">
                {isCompleted && editStation !== stationData.station ? (
                  <div className="text-center">
                    <h6 className="text-success">
                      <i className="fa-solid fa-check-circle me-2"></i>{" "}
                      การประเมินเสร็จสิ้น
                    </h6>
                    <button
                      className="btn btn-outline-warning btn-sm mt-2"
                      onClick={() => handleEditScore(stationData.station)}
                    >
                      <i className="fa-solid fa-pen"></i> แก้ไขคะแนน
                    </button>
                  </div>
                ) : (
                  <table className="table table-hover align-middle">
                    <thead className="table-light">
                      <tr>
                        <th className="text-start">คำถาม</th>
                        {point.map((score) => (
                          <th key={score} className="text-center">
                            {
                              ["น้อย", "พอใช้", "ปานกลาง", "ดี", "ดีมาก"][
                                score - 1
                              ]
                            }
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(stationData.question).map(
                        (questionKey, index) => (
                          <tr key={questionKey}>
                            <td className="text-start">
                              <strong className="fs-5">
                                {index + 1}.{" "}
                                {
                                  stationData.question[questionKey].split(
                                    "–"
                                  )[0]
                                }
                              </strong>
                              <div className="text-muted">
                                {
                                  stationData.question[questionKey].split(
                                    "–"
                                  )[1]
                                }
                              </div>
                            </td>
                            {point.map((score) => {
                              const average =
                                averages[stationData.station] || 1; // Default to 1 if not calculated
                              const adjustedScore = score * average;

                              return (
                                <td
                                  key={`${questionKey}-${adjustedScore}`}
                                  className="text-center"
                                >
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`${stationData.station}-question${
                                        index + 1
                                      }`}
                                      value={adjustedScore} // Adjusted score
                                      checked={
                                        scores[
                                          `${stationData.station}-Q${index + 1}`
                                        ] === adjustedScore
                                      }
                                      onClick={() => {
                                        handleScoreChange(
                                          stationData.station,
                                          index + 1,
                                          adjustedScore // Pass adjusted score
                                        );
                                      }}
                                      disabled={
                                        !(
                                          isCurrentStation ||
                                          editStation === stationData.station
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </div>

              {/* ปุ่มส่งการประเมิน */}
              <div className="card-footer d-flex justify-content-center">
                {!isCompleted ? (
                  <button
                    className="btn btn-primary mx-2"
                    onClick={() => handleSubmit(stationData.station)}
                    // Disable ปุ่มถ้าไม่ใช่ Station ปัจจุบัน
                    disabled={!isCurrentStation}
                  >
                    <i className="fa-solid fa-paper-plane me-2"></i>{" "}
                    ส่งการประเมิน
                  </button>
                ) : (
                  editStation === stationData.station && (
                    <button
                      className="btn btn-success mx-2"
                      onClick={() => handleSubmit(stationData.station)}
                    >
                      <i className="fa-solid fa-check-circle me-2"></i>{" "}
                      ยืนยันการแก้ไข
                    </button>
                  )
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Vote;
