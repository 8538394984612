import React, { useState, useRef } from "react";
import { BrowserMultiFormatReader } from "@zxing/browser";

const CheckTicket = () => {
  const [barcode, setBarcode] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserMultiFormatReader());

  const startScanner = async () => {
    try {
      // Get video input devices manually
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === "videoinput");

      if (videoDevices.length === 0) {
        throw new Error("No video input devices found");
      }

      const selectedDeviceId = videoDevices[0].deviceId;
      await codeReader.current.decodeOnceFromVideoDevice(selectedDeviceId, videoRef.current, (result) => {
        setBarcode(result.text);
        stopScanner(); // Stop the scanner after reading
      });

    } catch (err) {
      console.error(err);
      setIsScanning(false);
    }
  };

  const stopScanner = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop()); // Stop camera stream
    }
    setIsScanning(false);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary mb-4">Check Ticket</h2>

      <div className="row mb-3">
        <div className="col-8">
          <label htmlFor="barcodeInput" className="form-label">Code</label>
          <input
            type="text"
            className="form-control"
            id="barcodeInput"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            placeholder="Enter or scan barcode"
          />
        </div>
        <div className="col-4 d-flex align-items-end">
          <button
            className="btn btn-primary w-100"
            onClick={() => {
              if (!isScanning) {
                setIsScanning(true);
                startScanner();
              } else {
                stopScanner();
              }
            }}
          >
            {isScanning ? "Stop" : "Camera"}
          </button>
        </div>
      </div>

      {isScanning && (
        <div className="mt-4">
          <video ref={videoRef} id="video" style={{ width: "100%" }}></video>
        </div>
      )}
    </div>
  );
};

export default CheckTicket;
