import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "./../api";

const ContBoardscore = () => {
  const [allPayments, setAllPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedPayment2, setSelectedPayment2] = useState(null);
  const [resultPrice, setResultPrice] = useState();
  const [statusID, setStatusID] = useState(null); // สถานะที่ได้จาก API
  const [selectedStatus, setSelectedStatus] = useState(null); // สถานะที่เลือกจาก dropdown
  const [currentPage, setCurrentPage] = useState(1);
  const paymentsPerPage = 20;

  const getDataAllPayments = async () => {
    try {
      const response = await api.post(`/getAlldataTicket`);
      const allData = response.data.data;
      // กรองเฉพาะคีย์ที่เป็นตัวเลข
      const paymentsArray = Object.entries(allData)
        .filter(([key]) => !isNaN(key)) // กรองเฉพาะคีย์ที่เป็นตัวเลข
        .map(([, value]) => value); // แปลงกลับเป็น array ของ value
      const resultprice = response.data.data.resultPrice;
      setResultPrice(resultprice);
      setAllPayments(paymentsArray);
      setFilteredPayments(paymentsArray);
    } catch (error) {
      console.error("Error fetching payments data:", error);
    }
  };

  useEffect(() => {
    getDataAllPayments();
  }, []);

  const handleFilter = (status) => {
    if (status === "All") {
      setFilteredPayments(allPayments);
    } else {
      setFilteredPayments(
        allPayments.filter((payment) => payment.Status === status)
      );
    }
    setCurrentPage(1);
  };

  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = filteredPayments.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  const totalPages = Math.ceil(filteredPayments.length / paymentsPerPage);
  useEffect(() => {
    if (filteredPayments.length > 0) {
      const lastPage = Math.ceil(filteredPayments.length / paymentsPerPage);
      setCurrentPage(lastPage);
    }
  }, [filteredPayments, paymentsPerPage]);

  const handleVerified = async (payment) => {
    try {
      setLoading(true);
      if (payment.Status === "Verified") {
        // console.log("Status: ", payment.Status);
        alert("Payment is verified :");
      } else {
        // console.log("Status: ", payment.Status);
        // alert("Payment is not verified :");

        const TicketResponse = await api.post(`/getTicketID`, {
          classID: payment.ClassTicket,
          quantity: payment.quantity,
        });

        if (TicketResponse.status !== 200 || !TicketResponse.data.success) {
          throw new Error("Failed to get ticket data");
        }

        const DataTicket = TicketResponse?.data?.tickets ?? [];
        console.log("Tickets:", DataTicket);

        if (DataTicket.length > 0) {
          const updateResponse = await api.post(`/updateDataTicketSuccess`, {
            Status: "Verified",
            dataIndex: payment.dataId,
            price: payment.price,
            ticket: DataTicket,
            quantity: payment.quantity,
          });

          if (!updateResponse || updateResponse.status !== 200) {
            console.error("Failed to update data status");
            throw new Error("Failed to update data status to Verified");
          }

          console.log("Payment successfully verified");
        } else {
          console.warn("No tickets available to update");
        }

        const responsemessage = await api.post("/reportTicketVerify", {
          ToEmail: payment.email,
          dataIndex: payment.dataId,
          ticketData: DataTicket,
          classTicket: payment.ClassTicket,
          fullname: payment.fullName,
          quantity: payment.quantity,
          Price: payment.price,
        });

        if (responsemessage.status !== 200 || !responsemessage.data.success) {
          throw new Error("Failed to get ticket data");
        }
      }
    } catch (error) {
      console.error("Error in handleVerified:", error);
      alert("Payment is verified");
    } finally {
      setLoading(false); // ให้ `setLoading(false)` ทำงานเสมอเมื่อจบการทำงาน
      getDataAllPayments();
    }
  };

  const handleRejected = async (payment) => {
    try {
      setLoading(true);
      if (payment.Status === "Rejected") {
        alert("Payment is Rejected :");
      } else if (payment.Status === "Verified") {
        const ticketResponse = await api.post("/SetTicketReject", {
          tickets: payment.dataTicket,
          classID: payment.ClassTicket,
        });
        if (ticketResponse.status !== 200) {
          throw new Error("Failed to update ticket status");
        }

        const response = await api.post(`/updateDataTicketRejected`, {
          Status: "Rejected",
          dataIndex: payment.dataId,
          price: payment.price,
          quantity: payment.quantity,
        });

        if (response.status === 200) {
          console.log("Payment successfully rejected and score updated.");
          alert("Payment has been rejected successfully.");
        } else {
          throw new Error("Failed to update data status to Rejected");
        }
      } else if (payment.Status === "Waiting") {
        setLoading(true);
        const response = await api.post(`/updateDataTicketRejected`, {
          Status: "Rejected",
          dataIndex: payment.dataId,
          price: payment.price,
          quantity: payment.quantity,
        });
        if (response.status === 200) {
          console.log("Payment successfully rejected and score updated.");
          alert("Payment has been rejected successfully.");
        } else {
          throw new Error("Failed to update data status to Rejected");
        }
      }

      // if (response.status !== 200) {
      //   throw new Error("Failed to update data status to Rejected");
      // }

      // console.log("Payment successfully rejected and score updated.");

      // alert("Payment has been rejected successfully.");
    } catch (error) {
      console.error("Error in handleRejected:", error.message);
      alert("Status is not Verified");
    } finally {
      setLoading(false); // ให้ `setLoading(false)` ทำงานเสมอเมื่อจบการทำงาน
      getDataAllPayments();
    }
  };

  const handleShowModal = (payment) => {
    setSelectedPayment(payment);
    setShowModal3(true);
  };

  const handleShowModal2 = (payment) => {
    setSelectedPayment2(payment);
    setShowModal2(true);
  };

  return (
    <div className="container mt-5">
      <div className="row mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="btn-group">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleFilter("All")}
            >
              All Data
            </button>
            <button
              className="btn btn-outline-warning"
              onClick={() => handleFilter("Waiting")}
            >
              Waiting
            </button>
            <button
              className="btn btn-outline-success"
              onClick={() => handleFilter("Verified")}
            >
              Verified
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => handleFilter("Rejected")}
            >
              Rejected
            </button>
          </div>
          <h2 className="text-center mb-4 text-primary">Payment Dashboard</h2>
          <p className="fw-bold text-primary">
            Total Amount: {resultPrice || 0} บาท
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="p-4 bg-light border rounded shadow-sm">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Payment List</h5>
              {/* ปุ่ม Refresh */}
              <button
                className="btn btn-outline-primary"
                onClick={getDataAllPayments}
              >
                Refresh
              </button>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Payment List</h5>

              <button
                className="btn btn-outline-primary"
                onClick={getDataAllPayments}
              >
                Refresh
              </button>
            </div> */}

            {currentPayments.map((payment, index) => {
              // ใช้ allPayments เพื่อตรวจสอบค่าซ้ำแทน filteredPayments
              const duplicatePayments = allPayments.filter(
                (otherPayment) =>
                  otherPayment.refNumber === payment.refNumber &&
                  payment.refNumber !== "" &&
                  payment.refNumber !== "ไม่สามารถระบุได้" &&
                  otherPayment.dataId !== payment.dataId // ป้องกันการจับคู่กับตัวเอง
              );

              const duplicateDataIds = duplicatePayments
                .map((p) => p.dataId)
                .join(", ");

              return (
                <div
                  key={index}
                  className="d-flex justify-content-between p-3 mb-4 border border-grey rounded bg-white shadow-sm align-items-center"
                >
                  <div className="my-2 col-5">
                    <div className="d-flex align-items-start">
                      <div>
                        <strong>Data ID:</strong> {payment.dataId}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <strong>Name:</strong> {payment.fullName}
                      </div>
                      <div>
                        <strong>Phone:</strong> {payment.phonenumber}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <strong>Email:</strong>{" "}
                        <span className="text-muted">{payment.email}</span>
                      </div>
                      <div>
                        <strong>Quantity: </strong> {payment.quantity}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <strong>Price:</strong>{" "}
                        <span className="text-primary">
                          {payment.price} บาท
                        </span>
                      </div>
                      <div>
                        <strong>Date:</strong> {payment.dateTime}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <strong>Class:</strong> {payment.ClassTicket}
                      </div>
                      <div>
                        <p>
                          <strong>Status:</strong>{" "}
                          <span
                            className={`badge ${
                              payment.Status === "Verified"
                                ? "bg-success"
                                : payment.Status === "Rejected"
                                ? "bg-danger"
                                : "bg-warning"
                            }`}
                          >
                            {payment.Status}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <strong>เลขอ้างอิง:</strong>
                        <span
                          className={`px-3 py-1 rounded fw-bold ${
                            payment.refNumber === "" ||
                            payment.refNumber === "ไม่สามารถระบุได้"
                              ? "bg-warning text-dark"
                              : duplicatePayments.length > 0
                              ? "bg-danger text-white"
                              : "bg-light text-dark border"
                          }`}
                        >
                          {payment.refNumber}
                        </span>
                        {duplicatePayments.length > 0 && (
                          <span
                            className="text-danger fs-5"
                            title={`ซ้ำกับ Data ID: ${duplicateDataIds}`}
                          >
                            ⚠️
                          </span>
                        )}
                      </div>
                      {payment.dataTicket && (
                        <button
                          className="btn btn-dark text-white me-2"
                          onClick={() => handleShowModal2(payment)}
                        >
                          Ticket
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="my-2 col-4">
                    <div className="d-flex justify-content-between">
                      <button
                        className="btn btn-info text-white me-2"
                        onClick={() => handleShowModal(payment)}
                      >
                        View Image
                      </button>
                      <button
                        className="btn btn-success me-2"
                        onClick={() => handleVerified(payment)}
                        disabled={loading}
                      >
                        {loading ? "loading" : "Verify"}
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleRejected(payment)}
                        disabled={loading}
                      >
                        {loading ? "loading" : "Reject"}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            <nav>
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages).keys()].map((number) => (
                  <li
                    key={number + 1}
                    className={`page-item ${
                      currentPage === number + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(number + 1)}
                    >
                      {number + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {selectedPayment && (
        <div
          className={`modal fade ${showModal3 ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header bg-primary text-white">
                <h5 className="modal-title">Payment Image</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal3(false)}
                ></button>
              </div>
              <div className="modal-body">
                <img
                  src={selectedPayment.imagesURL}
                  alt={`Payment ${selectedPayment.dataId}`}
                  className="img-fluid rounded"
                />
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowModal3(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedPayment2 && (
        <div
          className={`modal fade ${showModal2 ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header bg-success text-white">
                <h5 className="modal-title">Ticket</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal2(false)}
                ></button>
              </div>
              <div className="modal-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">dataIndex</th>
                      <th scope="col">ticketID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPayment2.dataTicket.map((ticket, index) => (
                      <tr key={index}>
                        <td>{ticket.index}</td>
                        <td>{ticket.codeID}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowModal2(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContBoardscore;
